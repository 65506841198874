import { usePageBySlug } from "@byko/lib-api-dato-client";
import { withDatoPageBySlug } from "@byko/lib-api-dato-client";
import { BykoPage } from "@byko/scene-byko-page";
import { ErrorScene } from "@byko/scene-error";

import type { BykoPage as _BykoPage } from "@byko/lib-api-dato-client";

interface SlugProps {
  data?: _BykoPage | null;
  slug: string;
  notFound: boolean;
  internalError: boolean;
}

export const Slug = ({ slug, notFound, internalError, data: initialData }: SlugProps): JSX.Element | null => {
  const { data } = usePageBySlug({ slug, initialData });
  if (!slug || notFound) {
    // TODO: Add page not found
    return <ErrorScene code={404} />;
  }
  if (internalError || !data || "error" in data) {
    return <ErrorScene code={500} />;
  }

  return <BykoPage key={slug} page={data} />;
};

export default withDatoPageBySlug(Slug);
